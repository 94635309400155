import React, { Suspense, lazy, useState } from "react";

import Loader from "../../utils/Loader";

import VerifyMFA from "./VerifyMFA";
import MFASetup from "./MFASetup";

const Login = lazy(() => import("./Login"));

const LoginFlow = (props) => {
    const { setToken } = props || {};
    const [userEmail, setUserEmail] = useState(false);
    const [mfaStatus, setMfaStatus] = useState(false);
    const [password, setPassword] = useState("");

    return (
        <>
            <Suspense fallback={<Loader/>}>
                {!userEmail && <Login setMfaStatus={setMfaStatus} setUserEmail={setUserEmail} setUserPassword={setPassword} />}
            </Suspense>
            {(userEmail && !mfaStatus) && <MFASetup email={userEmail} setMfaStatus={setMfaStatus} />}
            {(userEmail && mfaStatus) && <VerifyMFA email={userEmail} password={password} redirectLogin={true} setToken={setToken} />}
        </>
    );
};

export default LoginFlow;
