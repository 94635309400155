import React, { useState } from "react";
import { Formik } from "formik";
import { Box, Button, Stack, useDisclosure, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import moment from "moment";

import { createContact, createPaymentAccount, goToNextStep, goToPreviousStep, resetContact, setAddAccount, setValues } from "../../../store/slices/contactSlices";
import { walletAddressValidation } from "../../../store/slices/recipientSlices";
import AddContactForm from "./AddContactForm";
import { validationSchema } from "./validateSchema";
import PaymentAccountDetails from "../payment-account-details/PaymentAccountDetails";
import bankFormConfig from "../payment-account-details/Fiat/BankFormConfig";
import BackModal from "../BackModal";

const contactSteps = ["Basic Details", "Account Details"];

const AddContactMain = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({ label: "Select Country" })
  const [selectEntityOptions, setSelectEntityOptions] = useState({ label: "Select Entity Type" })

  function renderStepComponent(step) {
    switch (step) {
      case 0:
        return <AddContactForm selectedOption={selectedOption} setSelectedOption={setSelectedOption} selectEntityOptions={selectEntityOptions} setSelectEntityOptions={setSelectEntityOptions} />;
      case 1:
        return <PaymentAccountDetails />;
    }
  }


  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  };

  const dispatch = useDispatch();
  const { step: activeStep } = useSelector((state) => state.contact);
  const isAddAccount = useSelector((state) => state.contact.isAddAccount);
  const contact = useSelector((state) => state.contact?.contactDetails?.contact);
  const contactId = useSelector((state) => state.contact?.contactDetails?.contact?.recipientId);
  const loading = useSelector((state) => state.contact.loading);
  const stableCoinsList = useSelector(state => state.order?.cryptoList, shallowEqual);
  const paymentAccountList = useSelector((state) => state.contact?.paymentAccounts) || [];

  const contactTypeMap = {
    "organization": "Business",
    "individual": "Individual",
  };

  const currentValidationSchema = isAddAccount ? validationSchema[activeStep] : null;
  const isLastStep = activeStep === contactSteps.length - 1;

  const handleBack = () => {
    onClose();
    if (paymentAccountList.length > 0) {
      handleToast("Please remove the added account to go back", 'error');
      return;
    }

    dispatch(goToPreviousStep());
  };

  async function handleSubmit(values, actions) {
    try {
      setIsLoading(true);

      if (!isAddAccount) {
        if (!paymentAccountList || paymentAccountList.length === 0) {
          handleToast("please add atleast one account to save the contact", 'error');
          actions.setSubmitting(false);
          return;
        }
        dispatch(resetContact());
        actions.resetForm();
        handleToast("Contact has been added", 'success');
        navigate("/contact");
        return;
      }
      if (isLastStep) {
        let extraFieldsWithValues = {};

        let finalSchemaToBeValidated = currentValidationSchema;
        let finalValuesToBeValidated = values;

        // In Case accountType is not crypto wallet
        if (values.accountType !== "crypto_wallet") {
          const pmGateway = values.bankName !== "" ? JSON.parse(values.bankName || {})?.pgId : {};
          const referenceName = values.bankName !== "" ? JSON.parse(values.bankName || {})?.referenceName : null;
          const currency = values.currency;
          let extraInitialValues = {}, extraValidationSchema = {};

          const { extraInitialValues: extraInitialValue, extraValidationSchema: extraValidationSchemas } = bankFormConfig(pmGateway, currency?.substring(0, 2), referenceName, contactTypeMap[contact?.type || "organization"]) || {};
          extraInitialValues = extraInitialValue || {};
          extraValidationSchema = extraValidationSchemas || {};

          const schema = Yup.object().shape({
            ...extraValidationSchema
          });

          finalSchemaToBeValidated = finalSchemaToBeValidated.concat(schema);

          function filterFieldsWithDefaults(mainObj, filterObj) {
            const result = {};
            Object.keys(filterObj).forEach((key) => {
              // also set the extraFieldsWithValues to be used in the final payload and only use the values that have something in them
              if (mainObj[key]) {
                extraFieldsWithValues[key] = mainObj[key];
              }
              result[key] = key in mainObj ? mainObj[key] : '';
            });
            return result;
          }

          const extraValues = filterFieldsWithDefaults(values, extraInitialValues);
          finalValuesToBeValidated = ({
            ...finalValuesToBeValidated,
            extraValues,
          })

        }

        // Wallet Address Validation 
        if (values.accountType === "crypto_wallet" && values.number !== '') {
          let status = true;

          const matchingObject = stableCoinsList?.find(item => item.value.formattedSymbol === values?.currency || "");
          const network = matchingObject?.value?.network || "";

          await dispatch(walletAddressValidation({ walletAddress: values?.number, currency: values?.currency, network })).then(async (resp) => {
            const { success: errorStatus = true } = resp?.payload || {};
            if (!errorStatus) {
              toast({
                title: resp?.payload?.error?.message || "Something Went Wrong",
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
              });
              status = false;
            }
          })
          if (!status) {
            actions.setSubmitting(false);
            return;
          }
        }

        // Validating Final Validation Values
        const isValid = await finalSchemaToBeValidated.isValid(finalValuesToBeValidated);
        if (!isValid) {
          const errors = await finalSchemaToBeValidated.validate(finalValuesToBeValidated, { abortEarly: false }).catch((err) => {
            if (err instanceof Yup.ValidationError) {
              // Create a more informative errors object
              const errorsInfo = {};
              err.inner.forEach((error) => {
                // Only add if the field hasn't been added yet, or if you want to override, remove this check
                if (!errorsInfo[error.path]) {
                  errorsInfo[error.path] = error.message;
                }
              });
              return errorsInfo;
            }

            // Handle unexpected errors
            return { unexpectedError: "An unexpected error occurred" };
          });

          actions.setErrors(errors);
          actions.setSubmitting(false);
          return;
        }

        const formattedDOB = moment(values?.dob || contact?.dob).format('YYYY-MM-DD');
        extraFieldsWithValues["dateofbirth"] = formattedDOB;

        dispatch(createPaymentAccount({
          contactId: contactId,
          type: values.accountType === "crypto_wallet" ? "crypto_wallet" : values.fiatType,
          currency: values.currency,
          accountNumber: values.number,
          displayName: values.nickname,
          extraFields: extraFieldsWithValues,
          paymentCode: values.accountType === "crypto_wallet" ? "" : JSON.parse(values?.bankName || "").referenceName,
        })).then(res => res?.payload).then(res => {
          const { error = {}, success = false } = res || {};
          const { message } = error;

          if (!success) {
            handleToast(message || "Something Went Wrong", 'error');
            return;
          }

          handleToast("New account has been added", 'success');
        }).catch(err => {
          handleToast("Something Went Wrong", 'error');
        });
      } else {
        let finalSchemaToBeValidated = currentValidationSchema;
        let finalValuesToBeValidated = values;

        // First update the contact with the regNumber and dob
        const contactUpdationData = {
          regNumber: values?.regNumber,
          dob: values?.dob,
        }

        let schema;
        if (values.contactType === "individual") {
          schema = Yup.object().shape({
            dob: Yup.string().required("Required"),
          });
        } else {
          // if the contact type is organization, then regNumber is required
          schema = Yup.object().shape({
            regNumber: Yup.string().required("Required"),
            dob: Yup.string().required("Required"),
          });
        }

        finalSchemaToBeValidated = finalSchemaToBeValidated.concat(schema);
        finalValuesToBeValidated = ({
          ...finalValuesToBeValidated,
          contactUpdationData,
        });

        const isValid = await finalSchemaToBeValidated.isValid(finalValuesToBeValidated);

        if (!isValid) {
          const errors = await finalSchemaToBeValidated.validate(finalValuesToBeValidated, { abortEarly: false }).catch((err) => {
            if (err instanceof Yup.ValidationError) {
              // Create a more informative errors object
              const errorsInfo = {};
              err.inner.forEach((error) => {
                // Only add if the field hasn't been added yet, or if you want to override, remove this check
                if (!errorsInfo[error.path]) {
                  errorsInfo[error.path] = error.message;
                }
              });
              return errorsInfo;
            }

            // Handle unexpected errors
            return { unexpectedError: "An unexpected error occurred" };
          });
          console.log(errors);

          actions.setErrors(errors);
          actions.setSubmitting(false);
          return;
        }

        dispatch(setValues(values));
        actions.setTouched({});
        actions.setSubmitting(false);
        dispatch(createContact({ ...values, product: 'payout' })).then(res => res?.payload).then(res => {
          if (!res) {
            handleToast("Failed to create contact", 'error');
            return;
          } else {
            const { error = {} } = res;
            if (!isEmpty(error)) {
              const { message = "Please Try Again!" } = error;
              handleToast(message, 'error');
              return;
            }
            dispatch(goToNextStep());
          }
        })

      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const initialValue = {
    name: "",
    email: "",
    entityType: "",
    contactType: "organization",
    country: "",
    accountType: "crypto_wallet",
    currency: "",
    bankName: "",
    dob: "",
    regNumber: "",
    number: "",
    nickname: "",
    fiatType: "bank",
  };

  return (
    <div>
      <div>
        <Formik
          initialValues={initialValue}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          {({ isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {renderStepComponent(activeStep)}
              <Box mt={-9} py={4} bg={"white"}>
                <div display="flex">
                  {activeStep !== 0 ? (
                    <Box display={"flex"} direction="row" justifyContent={"end"} marginTop={8} marginRight={10} columnGap={4}>
                      <Button disabled={paymentAccountList.length > 0} isDisabled={paymentAccountList.length > 0} variant={"secondary"} type="button" onClick={onOpen}>
                        Back
                      </Button>
                      <Button _loading={{ pointerEvents: "none" }} isLoading={loading || isLoading} variant={"primary"} type="submit">
                        {isLastStep ? "Save" : "Next"}
                      </Button>
                    </Box>
                  ) : (
                    <Stack direction="row" columnGap={5} justifyContent={"end"} paddingRight={10} marginTop={8}>
                      <Button variant={"secondary"} type="button" onClick={() => navigate("/contact")}>
                        Back
                      </Button>
                      <Button _loading={{ pointerEvents: "none" }} isLoading={loading || isLoading} isDisabled={isSubmitting} variant={"primary"} type="submit">Next</Button>
                    </Stack>
                  )}
                </div>
              </Box>
            </form>
          )}
        </Formik>
        <BackModal isOpen={isOpen} onClose={onClose} handleExit={handleBack} />
      </div>
    </div>
  );
};

export default AddContactMain;
