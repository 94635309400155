import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";

// Custom components and functions
import OTPInputBoxes from "./OTPInputBoxes";
import Loader from "../../utils/Loader";
import { callApi } from "../../utils/http_common";
import { verifyUser } from "../../store/slices/loginSlices";
import TransfiLogo from "../../assets/transfiLogo.svg";
import BackButtton from "../../components/LoginComponents/BackButton";
import StepsAsset from "../../assets/StepsAsset.png";
// CSS
import "./Login.css";
import "./PasswordLogin.css";
import HelmetWrapper from "../../utils/HelmetWrapper";
import { ArrowBackIcon } from "@chakra-ui/icons";

const VerifyMFA = (props) => {
  const toast = useToast();

  const {
    email,
    password,
    setOTPVerified,
    redirectLogin = false,
    setToken,
  } = props || {};
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const dispatch = useDispatch();

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 5000,
      isClosable: true,
    });
  };

  const verifyAccount = async (encryptedPassword, userEmail) => {
    try {
      dispatch(
        verifyUser({ email: userEmail, accountDetails: encryptedPassword })
      );
    } catch (e) {
      handleToast(e?.message || "Something went wrong", "error");
      setIsLoading(false);
    }
  };

  const verifyOtp = async () => {
    if (otp.length < 6) {
      handleToast("Please Enter Valid OTP", "error");
      return;
    }
    try {
      setIsButtonLoading(true);
      const { data } = await callApi.post(`/customer/verify2FA`, {
        email,
        otp,
        needLogin: true,
      });
      const { otpVerified = false, message, tokenData } = data?.data;

      if (otpVerified) {
        setIsLoading(true);
        if (redirectLogin) {
          await verifyAccount(password, email);  
        } else {
          setOTPVerified(otpVerified); 
          setIsLoading(false);
        }
      } else {
        throw new Error(message);
      }
      handleToast("MFA Successfully Verified", "success");
    } catch (e) {
      handleToast(
        e?.response?.data?.error?.message || "Something went wrong",
        "error"
      );
      setIsButtonLoading(false);
      setIsLoading(false);
    }
  };

  
  const handleNextClick = () => {
    verifyOtp(); 
  };

  return isLoading ? (
    <Loader zIndex={"0"} />
  ) : (
    <>
      <HelmetWrapper
        title="Transfi Pay Login Up"
        description="Transfi Pay Signup Page"
      />
      <Flex w="full" h="100vh" overflow="hidden">
        {/* Left Side */}
        <Flex flex={1} direction="column" p={14} justify="start">
          <Box>
            <Image src={TransfiLogo} alt="logo" w="150px" />
          </Box>

          <Box textAlign="start" marginTop={"14"}>
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="ghost"
              color={"#1A4FD6"}
              fontWeight={"600"}
              p={1}
              fontSize="sm"
            >
              Back
            </Button>
            <Text fontSize="3xl" fontWeight="normal" mb={2}>
              Enter 2FA code
            </Text>
            <Text fontSize="16px" fontWeight="400" color="#525C76">
              Enter the security code generated by your authenticator app to
              verify it’s you.
            </Text>
            <Box paddingY={10} spaceY={4}>
              <Text fontSize="sm" fontWeight="bold" marginBottom={3}>
                Enter OTP
              </Text>
              <OTPInputBoxes setOtp={setOtp} />
              <Text color={"#525C76"} fontSize="16px" fontWeight="400" mt={4}>
                Want to reset your 2FA?{" "}
                <Link color={"#1A4FD6"} fontWeight="600">
                  Contact TransFi support
                </Link>
              </Text>
            </Box>
            <VStack spacing={5} align="stretch">
              <Button
                onClick={handleNextClick} 
                bg="#1A4FD6"
                color="white"
                w="full"
                isLoading={isButtonLoading} 
                _hover={{ bg: "#163A9E" }}
                _active={{ bg: "#122E7F", transform: "scale(0.98)" }}
              >
                Next
              </Button>
            </VStack>
          </Box>
        </Flex>

        {/* Right Side */}
        <Flex
          flex={2}
          className="gradient-background"
          flexDirection="column"
          justify="center"
          align="center"
          textAlign="center"
          p={5}
        >
          <Box mb={6} align="center" justify="center">
            <Image src={StepsAsset} alt="Visual" w={"80%"} />
          </Box>

          <Box maxW="600px" align="start">
            <Text
              fontSize="14px"
              fontWeight="400"
              color="#092C4C"
              mb={4}
              align={"start"}
            >
              “I've had the pleasure of working with Transfi.com for the past
              year, and I must say, their commitment and support system have
              been nothing short of exemplary. The team's dedication to both
              technical and operational excellence is evident in how seamlessly
              and efficiently they handle every task.”
            </Text>
            <Text fontSize="24px" fontWeight="700" color="#092C4C">
              Suleman Kazim
            </Text>
            <Text fontSize="16px" fontWeight="400" color="#525C76">
              CEO - Parallax
            </Text>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default VerifyMFA;
