import { Box, Button, Flex, Image, Table, TableContainer, Tbody, Td, Tr, Icon, useDisclosure, Stack, Skeleton, Tooltip, Text, Thead, Th, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { BsInfoCircle, BsTrash } from "react-icons/bs";
import { startCase } from "lodash";

import Typography from '../../components/Common/Typography';
import ContactService from '../../services/contactService'
import DeleteModal from './DeleteModal';
import { BiPlus } from 'react-icons/bi';
import EmptyIcon from '../../assets/emptypage.svg';
import { LuPencil } from "react-icons/lu";
import { resetContact, setAddAccount } from '../../store/slices/contactSlices'

const Customer = () => {
    const [isAdmin, setIsAdmin] = useState(false)
    const [modalData, setModalData] = useState({})
    const [isContactsEmpty, setIsContactsEmpty] = useState(false);
    const [loading, setLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const dispatch = useDispatch();

    // const loading = true;
    const userDetails = useSelector((state) => state?.login?.userDetails)

    useEffect(() => {
        if (userDetails?.cxRoles.includes('admin')) {
            setIsAdmin(true)
        }
    }, [userDetails])

    console.log(contacts);

    const getAllContacts = async () => {
        try {
            setLoading(true);
            const response = await ContactService.getContacts("collection");
            if (!response?.data?.data?.contacts.length) {
                setIsContactsEmpty(true);
                return;
            }
            setContacts(response?.data?.data?.contacts)
        } catch (error) {
        } finally {
            setLoading(false);
            dispatch(resetContact());
            dispatch(setAddAccount(true))
        }
    }

    useEffect(() => {
        getAllContacts();
    }, [])

    const handleEditCustomer = (e) => {
        navigate('/customers/update-customer', { state: { customer: e } });
    }

    const handleDelete = (e) => {
        setModalData(e);
        onOpen();
    }

    if (loading) {
        return (
            <Stack rowGap={5} bg={"white"} padding={6}>
                {Array(4).fill(0).map((_, index) => (
                    <Skeleton height='60px' />
                ))}

            </Stack>
        )
    }

    if (isContactsEmpty) {
        return (
            <>
                <Box bg="white">
                    <div className='no-contact'>
                        <Image src={EmptyIcon} />
                        <Heading as='h3' size='md'>
                            No Collections Contact added
                        </Heading>
                        <p style={{ marginBottom: "10px" }}>Add a contact to start collecting money</p>
                        <Button onClick={() => navigate("/customers/add-customer")} variant={"primary"}>
                            Add Contact
                            <BiPlus style={{ marginLeft: "5px" }} />
                        </Button>
                    </div>
                </Box>
            </>
        )
    }

    return (
        <Box>
            <Flex justifyContent={"space-between"} alignItems="center">
                <Flex direction="column">
                    <Text fontSize="22px" mt="2">
                        Collections contact
                    </Text>
                    <Text color="#999999" fontSize="small">
                        The contacts created here will be available for collecting money
                    </Text>
                </Flex>
                <Button
                    onClick={() => navigate('/customers/add-customer')}
                    variant={"primary"}
                    px={5}
                >
                    Add Contact
                    <BiPlus style={{ marginLeft: "5px" }} />
                </Button>
            </Flex>


            {contacts.length > 0 &&
                <Box width={'full'} minHeight={'70vh'} mt={4} p={4}>
                    {loading ?
                        <Stack rowGap={5} bg={"white"}>
                            <Skeleton height='60px' />
                            <Skeleton height='30px' />
                            <Skeleton height='30px' />
                            <Skeleton height='30px' />
                            <Skeleton height='30px' />
                        </Stack> :

                        <TableContainer maxH="70vh" overflowY={'auto'} position={'relative'}>
                            <Table variant='simple'>
                                <Thead bg={"white"} position={"sticky"} top={0} zIndex={"sticky"}>
                                    <Tr>
                                        {["Name", "Type", "Actions"].map((label) => (
                                            <Th textTransform="none" fontSize="14" fontWeight={"normal"} paddingY={6} color={"brand.greySecondary"} key={label}>{label}</Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody >
                                    {contacts?.map((e, index) =>
                                        <Tr key={index}>
                                            <Td>
                                                <Flex gap={4}>
                                                    <Image src={e?.logo} w={'38px'} />
                                                    <Box>
                                                        <Typography color="secondary" colorweight="800" type='description' weight='medium'>{e.name || e.firstName + " " + e.lastName}</Typography>
                                                        <Typography color="secondary" type='caption' weight='regular'>{e.email}</Typography>
                                                    </Box>
                                                </Flex>
                                            </Td>
                                            <Td>
                                                <Typography color="secondary" colorweight="800" type='description' weight='medium'>{startCase(e.type)}</Typography>
                                            </Td>
                                            <Td>
                                                <Flex justifyContent={"left"} alignItems={"center"} gap={"20px"} >
                                                    <Button h={'32px'} size={'sm'} bg={'#FFFFFF'} border={"1px"} borderColor={"#F2F2F2"} rounded={'6px'} onClick={() => handleEditCustomer(e)} isDisabled={!isAdmin}>
                                                        <Icon as={LuPencil} color={isAdmin ? '#1A4FD6' : "grey"} fontSize={"18px"} />
                                                    </Button>

                                                    {/* <Button h={'32px'} size={'sm'} bg={'#FFFFFF'} border={"1px"} borderColor={"#F2F2F2"} rounded={'6px'} onClick={() => handleDelete(e)} isDisabled={!isAdmin}>
                                                        <Icon as={BsTrash} color={isAdmin ? 'red.400' : "grey"} fontSize={"18px"} />
                                                    </Button> */}

                                                    {(e?.status) === "pending" &&
                                                        <Tooltip hasArrow label='Compliance check in progress. Please check back in 24 hours, Collections from this customer will be halted in the interim' bg='white' color={"black"} arrowSize={15} placement='top' >
                                                            <Button h={'32px'} size={'sm'} bg={'#FFFFFF'} border={"1px"} borderColor={"#F2F2F2"} rounded={'6px'} >
                                                                <Icon as={BsInfoCircle} color={'red.400'} />
                                                            </Button>
                                                        </Tooltip>
                                                    }
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>}
                </Box>}
            <DeleteModal onClose={onClose} isOpen={isOpen} data={modalData} />
        </Box >
    );
};

export default Customer;
