import React, { useState } from "react";
import { Box, Flex, VStack, useToast } from "@chakra-ui/react";
import Step1AccountDetails from "./Step1";
import Step2VerifyOtp from "./Step2";
import Step3PersonalDetails from "./Step3";
import Step4BusinessDetails from "./Step4";
import Step5Final from "./Step5";

const SignUp = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    isChecked: "",
    dob: "",
    businessName: "",
    businessNumber: "",
    dateOfIncorporation: "",
    password: "",
    firstName: "",
    lastName: "",
    otp: "",
  });

  const toast = useToast();

  const handleNext = (email = null) => {
    setFormData((prevData) => ({ ...prevData }));
    setStep((prev) => prev + 1);
  };

  const handleBack = () => setStep((prev) => (prev > 1 ? prev - 1 : prev));

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleSubmit = () => {
    toast({
      title: "Sign-Up Successful",
      description: "You have successfully signed up!",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const renderStep = () => {
    const stepProps = { formData, handleChange, handleNext, handleBack };

    switch (step) {
      case 1:
        return <Step1AccountDetails {...stepProps} />;
      case 2:
        return <Step2VerifyOtp {...stepProps} />;
      case 3:
        return <Step3PersonalDetails {...stepProps} />;
      case 4:
        return (
          <Step4BusinessDetails {...stepProps} handleSubmit={handleSubmit} />
        );
      case 5:
        return <Step5Final {...stepProps} />;

      default:
        return null;
    }
  };

  return (
    <Flex flexDirection="column" align="center" justify="center" minH="100vh">
      <Box bg="white" rounded="md" shadow="md" w={"100%"}>
        <VStack spacing={6} align="stretch">
          {renderStep()}
        </VStack>
      </Box>
    </Flex>
  );
};

export default SignUp;
