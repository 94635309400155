import * as Yup from 'yup';

export const businessCustomerValidationSchema = Yup.object().shape({
    country: Yup.string().required('Country is required'),
    name: Yup.string().required('Business Name is required'),
    email: Yup.string().email('Invalid email format').required('Email ID is required'),
    regNumber: Yup.string().required('Business Registration Number is required'),
    dob: Yup.string().required('Business registration date is required'),
    billingAddress1: Yup.string().required('Address Line 1 is required'),
    billingAddress2: Yup.string(),
    billingCity: Yup.string().required('City is required'),
    billingPincode: Yup.number('Pincode can only be numbers').required('Pincode is required'),
    shippingAddress1: Yup.string(),
    shippingAddress2: Yup.string(),
    shippingCity: Yup.string(),
    shippingPincode: Yup.number('Pincode can only be numbers'),
});

export const businessShippingCustomerValidationSchema = Yup.object().shape({
    country: Yup.string().required('Country is required'),
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email format').required('Email ID is required'),
    regNumber: Yup.string().required('Business Registration Number is required'),
    billingAddress1: Yup.string().required('Address Line 1 is required'),
    billingAddress2: Yup.string(),
    billingCity: Yup.string().required('City is required'),
    billingPincode: Yup.number('Pincode can only be numbers').required('Pincode is required'),
    shippingAddress1: Yup.string().required('Address Line 1 is required'),
    shippingAddress2: Yup.string(),
    shippingCity: Yup.string().required('City is required'),
    shippingPincode: Yup.number('Pincode can only be numbers').required('Pincode is required'),
});

export const individualCustomerValidationSchema = Yup.object().shape({
    country: Yup.string().required('Country is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email format').required('Email ID is required'),
    dob: Yup.string().required('DOB is required'),
    phone: Yup.string(),
    billingAddress1: Yup.string().required('Address Line 1 is required'),
    billingAddress2: Yup.string(),
    billingCity: Yup.string().required('City is required'),
    billingPincode: Yup.number('Pincode can only be numbers').required('Pincode is required'),
    shippingAddress1: Yup.string(),
    shippingAddress2: Yup.string(),
    shippingCity: Yup.string(),
    shippingPincode: Yup.number('Pincode can only be numbers'),
});

export const individualShippingCustomerValidationSchema = Yup.object().shape({
    country: Yup.string().required('Country is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email format').required('Email ID is required'),
    nickname: Yup.string(),
    phone: Yup.string(),
    billingAddress1: Yup.string().required('Address Line 1 is required'),
    billingAddress2: Yup.string(),
    billingCity: Yup.string().required('City is required'),
    billingPincode: Yup.number('Pincode can only be numbers').required('Pincode is required'),
    shippingAddress1: Yup.string().required('Address Line 1 is required'),
    shippingAddress2: Yup.string(),
    shippingCity: Yup.string().required('City is required'),
    shippingPincode: Yup.number('Pincode can only be numbers').required('Pincode is required'),
});
