import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  VStack,
  } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";  
import HelmetWrapper from "../../utils/HelmetWrapper";
import TransfiLogo from "../../assets/transfiLogo.svg";
import Step5Asset from "../../assets/step5Asset.png";

const Step5Final = ({ formData, handleChange, handleNext, handleBack }) => {

  const navigate = useNavigate(); 

  
  const handleGoToDashboard = () => {
    navigate("/home"); 
  };
  return (
    <>
      <HelmetWrapper
        title="Transfi Pay Sign Up"
        description="Transfi Pay Signup Page"
      />
      <Flex w="full" h="100vh" overflow="hidden">
       {/* Left Side */}
        <Flex flex={1} direction="column" p={14} justify="start">
          <Box>
            <Image src={TransfiLogo} alt="logo" w="150px" />
          </Box>

          <Box textAlign="start" marginTop={"14"}>
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="ghost"
              onClick={handleBack}
              color={"#1A4FD6"}
              fontWeight={"600"}
              p={1}
            >
              Back
            </Button>
            <Text fontSize="3xl" fontWeight="normal" mb={8}>
              Your Account is Created!
            </Text>

            <VStack spacing={5} align="stretch">
              <Box
                w="full"
                align="start"
                
              >
                <Text
                  lineHeight={"6"}
                  fontSize="16px"
                  fontWeight="400"
                  color="#525C76"
                >
                  You will be given a{" "}
                  <span style={{ fontWeight: "600" }}>$1000/transaction</span> {" "}
                  and{" "}
                  <span style={{ fontWeight: "600" }}>
                    10,000$/month cumulative.
                  </span>
                  {" "}After you complete your KYB process, your limits will
                  increase.
                  </Text>
                <Text fontSize="16px" fontWeight="400" color="#525C76" paddingTop={4}>
                  You can complete your KYB now or return later.
                </Text>
              </Box>

              <VStack>
               
                <Button
                  onClick={handleNext}
                  bg="#1A4FD6"
                  color="white"
                  w="full"
                  isDisabled={
                    !formData.dateOfIncorporation || !formData.businessNumber
                  }
                  _hover={{ bg: "#163A9E" }}
                  _active={{ bg: "#122E7F", transform: "scale(0.98)" }}
                >
                 Complete KYB now
                </Button>
                <Button onClick={handleGoToDashboard} w="full" variant={"outline"} >
                Take me to Dashboard First
                </Button>
              </VStack>
            </VStack>
          </Box>
        </Flex>

        {/* Right Side */}
        <Flex
          flex={2}
          className="gradient-background"
          justify="flex-end" 
          align="center"  
          
        >
          {/* Image at the right corner */}
          <Box w="full" display="flex" justifyContent="flex-end">
            <Image src={Step5Asset} alt="Visual" w="70%" />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Step5Final;
