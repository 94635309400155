import * as Yup from 'yup';
import { entityType } from '../../../constants';

const contactValidationSchema = Yup.object().shape({
  contactType: Yup.string().oneOf(['organization', 'individual']).required('Contact type is required'),
  country: Yup.string().required('Country is required'),
  dob: Yup.string().required('DOB is required'),
  email: Yup.string().email('Invalid email format').matches(
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    'Please enter a valid email'
  ).required('Email ID is required'),
  entityType: Yup.string().when('contactType', (contactType, schema) => {
    return contactType.includes('organization')
      ? Yup.mixed().oneOf([
        ...entityType.map((entity) => entity.value),
      ]).required('Entity Type is required for businesses')
      : schema.notRequired();
  }),
  otherEntityType: Yup.string().when('entityType', (entityType, schema) => {
    return entityType.includes('other')
      ? Yup.string().required('Entity Type is required')
      : schema.notRequired();
  }),

  firstName: Yup.string().when('contactType', (contactType, schema) => {
    return contactType.includes('individual')
      ? Yup.string().required('Firstname is required')
      : schema.notRequired();
  }),
  lastName: Yup.string().when('contactType', (contactType, schema) => {
    return contactType.includes('individual')
      ? Yup.string().required('Lastname is required')
      : schema.notRequired();
  }),
  name: Yup.string().when('contactType', (contactType, schema) => {
    return contactType.includes('organization')
      ? Yup.string().required('Business name is required')
      : schema.notRequired();
  }),
  regNumber: Yup.string().when('contactType', (contactType, schema) => {
    return contactType.includes('organization')
      ? Yup.string().required('Business registration number is required')
      : schema.notRequired();
  }),
});

export const accountValidationSchema = Yup.object().shape({
  accountType: Yup.mixed().oneOf(['crypto_wallet', 'fiat']).required('Account type is required'),
  currency: Yup.string().required("Currency is required"),
  // number: Yup.string().when(['accountType', 'fiatType'], ([accountType, fiatType], schema) => {
  //   if (accountType === "fiat") {
  //     // Fiat can have two types of account: bank or local_wallet
  //     if (fiatType === "bank") {
  //       return Yup.string()
  //         .matches(/^[0-9]+$/, "Invalid Account Number")
  //         .required("Account Number is required")
  //     }
  //     if (fiatType === "local_wallet") {
  //      return Yup.string()
  //         .required("Mobile Number is required")
  //         .matches(/^[0-9]+$/, "Invalid Mobile Number")
  //         .min(8, "Must be min 8 digits")
  //         .max(12, "Must be maximum 12 digits")
  //     }
  //   } else {
  //     // Crypto wallet
  //     return Yup.string().required("Wallet Address is required");
  //   }
  // }),
  nickname: Yup.string().when('currency', (currency, schema) => {
    return currency.includes('COP')
      ? schema.notRequired()
      : Yup.string().required("Name is required");
  }),
  // if account type is fiat
  fiatType: Yup.string().when('accountType', (accountType, schema) => {
    return accountType.includes('fiat')
      ? Yup.mixed().oneOf(['bank', 'local_wallet']).required('Fiat type is required')
      : schema.notRequired();
  }),
  bankName: Yup.string().when('accountType', (accountType, schema) => {
    return accountType.includes('fiat')
      ? Yup.string().required("Bank Name is required")
      : schema.notRequired();
  }),
});

export const validationSchema = [contactValidationSchema, accountValidationSchema];
