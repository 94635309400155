import { callApiWithToken } from "../utils/http_common";

const createPaymentAccount = (payload) => {
  console.log("payload: ", payload)
  return callApiWithToken.post(`/contact/createPaymentAccount`, { ...payload });
}

const getContact = (id = "") => {
  return callApiWithToken.get(`/contact/get/${id}`)
}
const getContacts = (product) => {
  return callApiWithToken.get(`/contact/list/${product}`)
}

const createContact = (payload) => {
  return callApiWithToken.post(`/contact/create`, { ...payload });
}

const updateContact = (data) => {
  return callApiWithToken.patch(`/contact/update/${data?.userId}`, { ...data });
}

const deletePaymentAccount = (id = "") => {
  return callApiWithToken.patch(`/contact/delete/${id}`);
}

const getFiatPaymentMethods = ({ fiatTicker = "", type = "sell", pmType = "bank_transfer", }) => {
  return callApiWithToken.get(`/contact/fiatPaymentMethods?currency=${fiatTicker}&type=${type}&pmType=${pmType}`);
}

const getFiatCurrency = () => {
  return callApiWithToken.get(`/contact/getFiatCurrency`);
}
const getCurrencies = (name = "") => {
  return callApiWithToken.get(`/configuration/getProduct/${name}`);
}


const ContactService = {
  getContact,
  getContacts,
  createPaymentAccount,
  createContact,
  getFiatPaymentMethods,
  getFiatCurrency,
  updateContact,
  deletePaymentAccount,
  getCurrencies
}

export default ContactService;