import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  VStack,
  HStack,
  PinInput,
  PinInputField,
  useToast,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import HelmetWrapper from "../../utils/HelmetWrapper";
import TransfiLogo from "../../assets/transfiLogo.svg";
import Step6Asset from "../../assets/step6Asset.png";


const Step2VerifyOtp = ({
  formData,
  handleChange,
  handleBack,
  handleSubmit,
  handleNext,
}) => {
  const toast = useToast();
  const [otp, setOtp] = useState("");
  const [countdown, setCountdown] = useState(30); // Countdown timer for OTP resend
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const handleResendOtp = () => {
    setIsResendDisabled(true);
    setCountdown(30); // Reset countdown to 30 seconds

    // Simulate OTP resend (this should call an API to send a new OTP)
    toast({
      title: "OTP Resent!",
      description: "A new OTP has been sent to your email.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setIsResendDisabled(false);
    }
  }, [countdown]);

  const email= formData.email;
  return (
    <>
      <HelmetWrapper
        title="Transfi Pay Sign Up"
        description="Transfi Pay Signup Page"
      />
      <Flex w="full" h="100vh" overflow="hidden">
        {/* Left Side */}
        <Flex flex={1} direction="column" p={14} justify="start">
          {/* Logo */}
          <Box>
            <Image src={TransfiLogo} alt="logo" w="150px" />
          </Box>

          {/* Form Section */}
          <Box textAlign="start" marginTop={"14"}>
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="ghost"
              onClick={handleBack}
              padding={1}
              color={"#1A4FD6"}
              fontWeight={"600"}
            >
              Back
            </Button>
            <Text fontSize="3xl" fontWeight="normal" mb={4}>
              Verify your email Address
            </Text>

            <VStack spacing={4} align="stretch">
              <Text fontSize="14px" fontWeight="400" color="#525C76">
                An OTP has been sent to {email} 
              </Text>
              <Text fontSize="sm" fontWeight="bold">
                Enter OTP
              </Text>
              <HStack justify="center" w="full" gap={3}>
                <PinInput
                  value={formData.otp}
                  onChange={(value) => handleChange("otp", value)}
                  size="lg"
                  spacing={4}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
              <Button
                onClick={handleResendOtp}
                padding={1}
                fontSize={"sm"}
                variant="unstyled"
                alignSelf="flex-end"
                isDisabled={isResendDisabled} 
                _hover={{ color: "#163A9E" }}
                _active={{ bg: "#122E7F", transform: "scale(0.98)" }}
              >
                {isResendDisabled ? `Resend OTP (${countdown}s)` : "Resend OTP"}
              </Button>
              <Button
                onClick={() => {
                  console.log("Form Data on Next Click:", formData);
                  handleNext();
                }}
                bg="#1A4FD6"
                color="white"
                w="full"
                isDisabled={!formData.otp}
                _hover={{ bg: "#163A9E" }}
                _active={{ bg: "#122E7F", transform: "scale(0.98)" }}
              >
                Next
              </Button>
            </VStack>
          </Box>
        </Flex>

        {/* Right Side */}
        <Flex
          flex={2}
          className="gradient-background"
          flexDirection="column"
          justify="center"
          align="center"
          textAlign="center"
          p={5}
        >
          <Box mb={6} align="center" justify="center">
            <Image src={Step6Asset} alt="Visual" w={"60%"} />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Step2VerifyOtp;
