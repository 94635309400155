import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Text,
  useClipboard,
  useToast,
  VStack,
  Button,
  Tooltip,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
// Custom Components and functions
import { callApi } from "../../utils/http_common";
import Loader from "../../utils/Loader";
import QR from "../../assets/qr.svg";
import QRCode from "../../assets/QrCode.png";
// css
import "./Login.css";
import "./PasswordLogin.css";

// icons
import CopyIcon from "../../assets/copyIcon.svg";
import TransfiLogo from "../../assets/transfiLogo.svg";
import { ArrowBackIcon } from "@chakra-ui/icons";
import HelmetWrapper from "../../utils/HelmetWrapper";
import StepsAsset from "../../assets/StepsAsset.png";

const MFASetup = (props) => {
  const toast = useToast();
  const { value, setValue, hasCopied, onCopy } = useClipboard("");
  const { email, setMfaStatus } = props || {}; 
  const [isLoading, setIsLoading] = useState(false);

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 5000,
      isClosable: true,
    });
  };

  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [base32Code, setBase32Code] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    fetchQrCodeUrl();
  }, []);

  const fetchQrCodeUrl = async () => {
    try {
      setIsLoading(true);
      const { data } =
        (await callApi.post(
          "/customer/getMfaCode",
          JSON.stringify({ email })
        )) || {};
      const { status, qrImage, secret, message } = data?.data;
      if (status) {
        setQrCodeUrl(qrImage);
        setBase32Code(secret);  
        setValue(secret);  
      } else {
        throw new Error(message);
      }
      setIsLoading(false);
    } catch (e) {
      handleToast(
        e?.response?.data?.error?.message || "Something went wrong",
        "error"
      );
      setIsLoading(false);
    }
  };

  
  const handleNextClick = () => {
    setMfaStatus(true);  
  };
  const handleBack = () => {
   navigate(-1); 
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <HelmetWrapper
        title="Transfi Pay Login Up"
        description="Transfi Pay Signup Page"
      />
      <Flex w="full" h="100vh" overflow="hidden">
        {/* Left Side */}
        <Flex flex={1} direction="column" p={14} justify="start">
          <Box>
            <Image src={TransfiLogo} alt="logo" w="150px" />
          </Box>

          <Box textAlign="start" marginTop={"14"}>
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="ghost"
              onClick={handleBack}
              color={"#1A4FD6"}
              fontWeight={"600"}
              p={1}
              fontSize="sm"
            >
              Back
            </Button>
            <Text fontSize="3xl" lineHeight={"40px"} fontWeight="normal">
              Setup 2FA
            </Text>
            <Text
              lineHeight="4"
              fontSize="14px"
              fontWeight="400"
              color="#525C76"
            >
              Using an authenticator app like Google authenticator or Microsoft
              authenticator to scan the below QR code or enter the code below to
              continue.
            </Text>
            <Center marginTop={4} flexDirection="column">
              <HStack>
                <Image src={QR} w="15px" />
                <Text fontSize={"sm"} color={"#1A4FD6"}>
                  Scan Code
                </Text>
              </HStack>
              <Image src={QRCode} />
            </Center>
            <VStack align="stretch">
              <Box
                w="full"
                align="start"
                backgroundColor={"#F9F9F9"}
                borderRadius={8}
                p={4}
                marginTop={12}
              >
                <Text
                  lineHeight={"6"}
                  fontSize="16px"
                  fontWeight="600"
                  color="#525C76"
                  align={"center"}
                >
                  Scan not working?
                </Text>
                <Text
                  fontSize="14px"
                  fontWeight="400"
                  color="#808080"
                  align={"center"}
                >
                  Copy the below code key or enter it manually in your
                  Authenticator app.
                </Text>
                <HStack align={"center"} justify={"center"} spacing={2}>
                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    color="#1A4FD6"
                    align={"center"}
                  >
                    {base32Code} {/* Dynamically render the base32 code */}
                  </Text>
                  <Tooltip
                    label={hasCopied ? "Copied!" : "Click to copy"}
                    aria-label="A tooltip"
                  >
                    <Image src={CopyIcon} onClick={onCopy} cursor="pointer" />
                  </Tooltip>
                </HStack>
              </Box>
              <Button
                onClick={handleNextClick}  
                bg="#1A4FD6"
                color="white"
                w="full"
                _hover={{ bg: "#163A9E" }}
                _active={{ bg: "#122E7F", transform: "scale(0.98)" }}
              >
                Next
              </Button>
            </VStack>
          </Box>
        </Flex>

        {/* Right Side */}
        <Flex
          flex={2}
          className="gradient-background"
          flexDirection="column"
          justify="center"
          align="center"
          textAlign="center"
          p={5}
        >
          <Box mb={6} align="center" justify="center">
            <Image src={StepsAsset} alt="Visual" w={"70%"} />
          </Box>

          <Box maxW="600px" align="start">
            <Text
              fontSize="14px"
              fontWeight="400"
              color="#092C4C"
              mb={4}
              align={"start"}
            >
              “I've had the pleasure of working with Transfi.com for the past
              year, and I must say, their commitment and support system have
              been nothing short of exemplary. The team's dedication to both
              technical and operational excellence is evident in how seamlessly
              and efficiently they handle every task.”
            </Text>
            <Text fontSize="24px" fontWeight="700" color="#092C4C">
              Suleman Kazim
            </Text>
            <Text fontSize="16px" fontWeight="400" color="#525C76">
              CEO - Parallax
            </Text>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default MFASetup;
