import React, { useState, useEffect } from 'react';
import { isEmpty } from "lodash";

import { CustomSelectFieldSearch, TextField } from '../contact/add-contact/InputField';
import { Box, Checkbox, Flex, Grid, GridItem, Image, Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { DatePickerField } from "../../components/Common/Input";

import Typography from '../../components/Common/Typography';
import { phoneMap } from '../../utils/phoneNumberMaps';

const Individual = ({ fiatList, selectedOption, setSelectedOption, setPhone, initialValues, addressSame, setAddressSame }) => {
    const [values, setValues] = useState(initialValues);
    const [selectedDate, setSelectedDate] = useState({ label: "dd-mm-yyyy", value: "" });

    useEffect(() => {
        if (initialValues) {
            setValues(initialValues)
            setSelectedOption(initialValues.countryObj);
        }
    }, []);

    async function handleChange(fieldName, value) {
        if (fieldName === "phone") {
            const phoneNumber = phoneMap[selectedOption?.value]?.code + value;
            setPhone(phoneNumber);
        }
        const updatedValues = { ...values, [fieldName]: value };
        setValues(updatedValues);
    };
    return (
        <>
            <Grid templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={8}>
                <GridItem maxW={'386px'}>
                    <TextField label="First Name" name="firstName" placeholder="Enter First Name" disabled={!isEmpty(initialValues)} />
                </GridItem>
                <GridItem maxW={'386px'}>
                    <TextField label="Last Name" name="lastName" placeholder="Enter Last Name" />
                </GridItem>
                <GridItem>
                    <CustomSelectFieldSearch EmptyMenuLabel={"No country/Region Found"} label={"Country/Region"} options={fiatList || []} name="country" placeholder="Select Country/Region" selectedOption={selectedOption} setSelectedOption={setSelectedOption} isDisabled={!isEmpty(initialValues)} />
                </GridItem>
                <GridItem maxW={'386px'}>
                    <TextField label="Email ID" name="email" type="email" placeholder="Enter Email" disabled={!isEmpty(initialValues)} />
                </GridItem>
                <GridItem maxW={'386px'}>
                    <Typography type="description" weight="regular" color="secondary" colorweight={"800"}>
                        Phone Number
                    </Typography>
                    <InputGroup mt={2}>
                        {selectedOption?.logo && <Image src={selectedOption?.logo} w={'20px'} mr={'3'} />}
                        <InputLeftAddon>
                            {phoneMap[selectedOption?.value]?.code || '+XX'}
                        </InputLeftAddon>
                        <Input type='tel' value={values?.phone} onChange={(e) => handleChange('phone', e.target.value)} />
                    </InputGroup>
                </GridItem>
                <GridItem maxW={'386px'}>
                    <DatePickerField isTouch={false} label={"Date of Birth"} name="dob" placeholderText="dd-mm-yyyy" selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                </GridItem>
            </Grid>
            <Box mt={7} mb={6}>
                <Typography type='body' weight='medium' color='primary' >
                    Billing Address
                </Typography>
            </Box>
            <Grid templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={8}>
                <GridItem maxW={'386px'}>
                    <TextField label="Address Line 1" name="billingAddress1" placeholder="Enter Address Line 1" />
                </GridItem>
                <GridItem maxW={'386px'}>
                    <TextField label="Address Line 2" name="billingAddress2" placeholder="Enter Address Line 1" required={false} />
                </GridItem>
                <GridItem maxW={'386px'}>
                    <TextField label="City" name="billingCity" placeholder="Enter City" />
                </GridItem>
                <GridItem maxW={'386px'}>
                    <TextField label="Pincode" name="billingPincode" placeholder="Enter Pincode" />
                </GridItem>
            </Grid>
            <Box mt={7} mb={6}>
                <Typography type='body' weight='medium' color='primary' >
                    Shipping Address
                </Typography>
            </Box>
            <Flex mt={5} mb={5} gap={"8px"}>
                <Checkbox
                    isChecked={addressSame}
                    onChange={() => setAddressSame(!addressSame)}
                />
                <Typography type='body' weight='medium' color='secondary' colorweight='800' >
                    Same as billing address
                </Typography>
            </Flex>
            {!addressSame &&
                <Grid templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={8}>
                    <GridItem maxW={'386px'}>
                        <TextField label="Address Line 1" name="shippingAddress1" placeholder="Enter Address Line 1" />
                    </GridItem>
                    <GridItem maxW={'386px'}>
                        <TextField label="Address Line 2" name="shippingAddress2" placeholder="Enter Address Line 1" required={false} />
                    </GridItem>
                    <GridItem maxW={'386px'}>
                        <TextField label="City" name="shippingCity" placeholder="Enter City" />
                    </GridItem>
                    <GridItem maxW={'386px'}>
                        <TextField label="Pincode" name="shippingPincode" placeholder="Enter Pincode" />
                    </GridItem>
                </Grid>
            }
        </>
    );
};

export default Individual;