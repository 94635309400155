import { Box, VStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useField } from "formik";

import { getFiatCountryList, getFiatList } from "../../../store/slices/contactSlices";
import { RadioField, TextField, CustomSelectField, CustomSelectFieldSearch, DatePickerField } from "../../../components/Common/Input";
import { entityType } from "../../../constants";
import MainCard from "./MainCard";

// let's use the useField feature to show the values.. let's stringify the values for the logos.

const AddContactForm = (props) => {
  const { selectedOption, setSelectedOption, selectEntityOptions, setSelectEntityOptions } = props
  const dispatch = useDispatch();

  const contactTypeOptions = [{ value: 'organization', label: 'Business' }, { value: 'individual', label: 'Individual' }]
  const fiatList = useSelector((state) => state?.contact?.countryList?.map(item => ({
    label: `${item.symbol}`,
    value: `${item.countryCode2Digit}`,
    logo: `${item.logo}`
  })));

  // const [selectedOption, setSelectedOption] = useState({ label: "Select Country" });
  const [selectedDate, setSelectedDate] = useState({ label: "dd-mm-yyyy", value: "" });

  const [contactType] = useField('contactType');
  const [entity] = useField('entityType');
  const isOrganization = contactType.value === "organization";

  useEffect(() => {
    dispatch(getFiatCountryList());
  }, [])

  return (
    <MainCard marginTop={-4} title="Basic Details">
      <VStack width="386px" spacing={5}>
        <RadioField label={"Contact Type"} options={contactTypeOptions} name="contactType" placeholder="Contact Type" />

        {contactType.value === "individual" && (
          <>
            <TextField label="First Name" name="firstName" placeholder="Enter First Name" />
            <TextField label="Last Name" name="lastName" placeholder="Enter Last Name" />
            <TextField label={"Individual Email ID"} type="email" name="email" placeholder="Enter Email" />
          </>
        )}

        {contactType.value === "organization" && (
          <>
            <TextField label="Business Name" name="name" placeholder="Enter Name" />
            <TextField label="Business Registration Number" name="regNumber" placeholder="Enter valid Number" />
            <TextField label={"Business Email ID"} type="email" name="email" placeholder="Enter Email" isTouch={false} />
          </>
        )}

        <DatePickerField isTouch={false} label={isOrganization ? "Business Registration Date" : "Date of Birth"} name="dob" placeholderText="dd-mm-yyyy" selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        <CustomSelectFieldSearch EmptyMenuLabel={"No country/Region Found"} label={"Country/Region"} options={fiatList || []} name="country" placeholder="Select Country/Region" selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
        {contactType.value === "organization" && <CustomSelectField label={"Entity Type"} options={entityType || []} name="entityType" placeholder="Entity Type" selectedOption={selectEntityOptions} setSelectedOption={setSelectEntityOptions} />}
        {entity.value === "other" && <TextField label="Entity Type" name="otherEntityType" placeholder="Enter Entity Type" />}

      </VStack>
    </MainCard>
  );
};

export default AddContactForm;
