import { Box, Button, Grid, SimpleGrid, Stack, VStack } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useField } from "formik";

import DynamicFields from "./DynamicField";
import { TextField } from "../../../../components/Common/Input";
import UploadInvoice from "./uploadInvoice";

const extraFieldsPartners = [
    "PG-230824305141379", //bitssa
    "PG-230824305141380",//directa24
    "PG-230914305141379",
    "PG-240321191632238",
    "PG-231226142412429",
    "PG-231017161345295",
    "PG-231226142202982", //dlocal
    "PG-290124014234498", //yellowcard
    "PG-240226201619253", //bitso
    "PG-240312134719876", // tripleA
    "PG-240416072834585", // "koywe"
    "PG-240702052513488", //local_payment
];

const contactTypeMap = {
    "organization": "Business",
    "individual": "Individual",
};


const AddBankForm = ({ bank, isAdditionalRequired = true }) => {

    const contactDetails = useSelector((state) => state.contact?.contactDetails);
    const contactType = contactTypeMap[contactDetails?.contact?.type || "individual"];

    const [currency] = useField("currency")
    const [bankName] = useField("bankName")
    let pmGateway = "";
    let referenceName = "";

    try {
        const jsonString = bank || bankName?.value || "";
        if (jsonString && typeof jsonString === "string") {
            const parsedObject = JSON.parse(jsonString);
            pmGateway = parsedObject?.pgId || "";
            referenceName = parsedObject?.referenceName || null;
        }
    } catch (error) {
        console.error("Failed to parse JSON:", error);
        pmGateway = "";
        referenceName = null;
    }

    return (
        <Box>
            <SimpleGrid columns={2} spacing={6}>
                {(!["COP"].includes(currency.value)) && <TextField label={"Bank Account Holder Name"} name="nickname" placeholder="Enter Bank account holder's name" />}
                {(!["sepa_bank_va", "sepa_bank"].includes(referenceName)) && <TextField label={"Bank Account Number"} name="number" placeholder="Enter Bank account number" />}
                {
                    extraFieldsPartners.includes(pmGateway) && (
                        <DynamicFields pmGateway={pmGateway} currency={currency.value} paymentCode={referenceName} contactType={contactType} />
                    )
                }

            </SimpleGrid>
            {currency.value === "USD" && <UploadInvoice />}
        </Box>
    );
};

export default AddBankForm;
