import React, { useEffect, useState } from 'react';
import { Box, Button, HStack, Img, Stack, VStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { useField, useFormikContext } from "formik";

import InformationIcon from '../../../assets/information-circle.svg';

// css
import '../Contact.css';

// Common Components and Function
import Card from '../../../components/Common/Card';
import Typography from '../../../components/Common/Typography';
import { CustomSelectField, CustomSelectFieldSearch, RadioField, SelectField, SelectMenu } from '../../../components/Common/Input';
import { getCurrencyConfigs } from '../../../store/slices/orderSlices';
import WalletAddressCard from './WalletAddressCard';
import WalletViewCard from './WalletViewCard';
import RadioDropdown from './Fiat/RadioDropdown';
import AddBankForm from './Fiat/AddBankForm';
import AddWallet from './Fiat/AddWallet';


const PaymentAccountDetails = (props) => {

  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [currency] = useField('currency')
  const [accountType] = useField('accountType')
  const [bankName] = useField('bankName')
  const [fiatType] = useField('fiatType')

  const dispatch = useDispatch();
  const stableCoins = useSelector(state => state.order?.cryptoList).map((item) => {
    return {
      label: `${item.value.symbol} - ${item.value.description} ( ${item.value.network} )`,
      value: `${item.value.formattedSymbol}`,
      logo: `${item.value.logo}`
    };
  });
  const fiatList = useSelector(state => state.order?.fiatList).map((item) => {
    return {
      label: `${item.value.symbol} - ${item?.value?.description || item?.value?.countryCode2Digit}`,
      value: `${item.value.description || item.value?.countryCode2Digit}`,
      logo: `${item.value.logo}`
    };
  });

  const [selectedOption, setSelectedOption] = useState({ label: "Select Digital Asset" });
  const [selectedFiatOption, setSelectedFiatOption] = useState({ label: "Select Currency" });


  const options = [
    {
      value: "crypto_wallet",
      label: "Digital Assets (USDT, USDC)"
    },
    {
      value: "fiat",
      label: "Fiat currency (EUR, BRL)"
    }
  ];

  const isAddAccount = useSelector((state) => state.contact?.isAddAccount);
  const contactDetails = useSelector((state) => state.contact?.contactDetails);

  useEffect(() => {
    dispatch(getCurrencyConfigs({ product: "pay_out" }));
  }, []);

  useEffect(() => {
    if (accountType.value === "fiat") {
      setSelectedFiatOption({ label: "Select Currency" })
      setFieldValue("nickname", "");
      setFieldValue("number", "");
      setFieldTouched("nickname", false);
      setFieldTouched("number", false);
    } else if (accountType.value === "crypto_wallet") {
      setSelectedOption({ label: "Select Digital Asset" })
      setFieldValue("nickname", "");
      setFieldValue("number", "");
      setFieldTouched("nickname", false);
      setFieldTouched("number", false);
    }
  }, [accountType.value])

  const { name, firstName, lastName } = contactDetails?.contact || {};
  const fullName = name || firstName + " " + lastName

  const CurrencySelectionCard = () => {
    return (
      <Card>
        <VStack align="stretch" spacing="20px">
          <HStack >
            <Typography weight='medium' type='subtitle' color='primary' >
              Account Details
            </Typography>
            <div className='ad-line' ></div>
            <Img src={process.env.PUBLIC_URL + contactDetails?.contact?.logo} borderRadius={"50px"} border={"1px solid #F5F5F5"} w={"20px"} h={"20px"} />
            <Typography weight='medium' type='body' color='secondary' >
              {fullName}
            </Typography>
          </HStack>
          <VStack align="flex-start" >
            <Typography weight='regular' type='description' color='secondary' colorweight='800' >
              In what currency does the individual like to receive money?
            </Typography>
            <RadioField options={options} name="accountType" placeholder="Select Account Type" />
          </VStack>
          {accountType.value === "crypto_wallet" &&
            <CustomSelectFieldSearch label={"Select Digital Asset"} options={stableCoins} name="currency" placeholder="Select" selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
          }
          {accountType.value === "fiat" && (
            <CustomSelectFieldSearch EmptyMenuLabel={"No Currency Found"} label={"Select Currency"} options={fiatList} name="currency" placeholder="Select Currency" selectedOption={selectedFiatOption} setSelectedOption={setSelectedFiatOption} />
          )}
        </VStack>
      </Card>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap="16px"
    // maxH={"70vh"} overflowY={"inherit"}
    >
      {
        isAddAccount &&
        <CurrencySelectionCard />
      }
      {
        !isAddAccount &&
        <WalletViewCard />
      }
      {(accountType.value === "crypto_wallet" && currency.value && selectedOption?.value && isAddAccount) && (
        <WalletAddressCard />
      )}
      {(accountType.value === "fiat" && currency.value && selectedFiatOption?.value && isAddAccount) && (
        <Box width={"100%"}>
          <Card>
            <VStack align="stretch" spacing="10px" w='100%'>
              <VStack align="flex-start" >
                <Typography weight='medium' type='subtitle' color='primary' >
                  Payment Method
                </Typography>
                <HStack>
                  {/* <img src={InformationIcon} alt="icon" className='wa-information-icon' /> */}
                  <Typography weight='regular' type='description' colorweight='800' >
                    Please make sure that these details are correct
                  </Typography>
                </HStack>
              </VStack>
              <Stack paddingY={5} gap={"32px 10px"}>
                <RadioDropdown currency={currency.value} />
                {bankName.value && fiatType.value === "bank" && (
                  <AddBankForm />
                )}
                {bankName.value && fiatType.value === "local_wallet" && (
                  <AddWallet />
                )}

              </Stack>
            </VStack>
          </Card>
        </Box>
      )}
    </Box>
  );
}

export default PaymentAccountDetails;