import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  Input,
  VStack,
  FormControl,
  FormLabel,
  
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import HelmetWrapper from "../../utils/HelmetWrapper";
import TransfiLogo from "../../assets/transfiLogo.svg";
import StepsAsset from "../../assets/StepsAsset.png"

const Step4BusinessDetails = ({
  formData,
  handleChange,
  handleNext,
  handleBack,
  handleSubmit,
}) => {
  

return (
    <>
      <HelmetWrapper
        title="Transfi Pay Sign Up"
        description="Transfi Pay Signup Page"
      />
      <Flex w="full" h="100vh" overflow="hidden">
        <Flex flex={1} direction="column" p={14} justify="start">
          <Box>
            <Image src={TransfiLogo} alt="logo" w="150px" />
          </Box>

          <Box textAlign="start" marginTop={"14"}>
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="ghost"
              onClick={handleBack}
              color={"#1A4FD6"}
              fontWeight={"600"}
              p={1}
            >
              Back
            </Button>
            <Text fontSize="3xl" fontWeight="normal" mb={8}>
              Additional business details
            </Text>

            <VStack spacing={5} align="stretch">
              <FormControl isRequired>
                <FormLabel fontWeight="light" fontSize="sm">
                  Business date of incorporation
                </FormLabel>
                <Input
                  backgroundColor={"#F7FAFC"}
                  type="date"
                  value={formData.dateOfIncorporation || ""}
                  onChange={(e) =>
                    handleChange("dateOfIncorporation", e.target.value)
                  }
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontWeight="light" fontSize="sm">
                  Business Incorporation number
                </FormLabel>
                <Input
                  backgroundColor={"#F7FAFC"}
                  type="number"
                  placeholder="Enter Business Incorporation Number"
                  value={formData.businessNumber || ""}
                  onChange={(e) =>
                    handleChange("businessNumber", e.target.value)
                  }
                />
              </FormControl>
              <Box
                w="full"
                align="start"
                border={"1px solid #E2E8F0"}
                borderRadius={8}
                p={4}
                marginTop={12}
              >
                <Text
                  lineHeight={"6"}
                  fontSize="16px"
                  fontWeight="400"
                  color="#525C76"
                >
                  We need these details to give you a transactional limit of{" "}
                  <span style={{ fontWeight: "600" }}>
                    $1000/transaction and 10,000$/month cumulative.{" "}
                  </span>
                </Text>
                <Text fontSize="16px" fontWeight="400" color="#525C76">
                  If you don’t have it now you can skip and add later on.{" "}
                </Text>
              </Box>

              <VStack>
                <Button onClick={handleNext} w="full" variant={"outline"}>
                  Skip
                </Button>
                <Button
                  onClick={handleNext}
                  bg="#1A4FD6"
                  color="white"
                  w="full"
                  isDisabled={
                    !formData.dateOfIncorporation || !formData.businessNumber
                  }
                  _hover={{ bg: "#163A9E" }}
                  _active={{ bg: "#122E7F", transform: "scale(0.98)" }}
                >
                  Next
                </Button>
              </VStack>
            </VStack>
          </Box>
        </Flex>

        {/* Right Side */}
        <Flex
          flex={2}
          className="gradient-background"
          flexDirection="column"
          justify="center"
          align="center"
          textAlign="center"
          p={5}
        >
          <Box mb={6} align="center" justify="center">
            <Image src={StepsAsset} alt="Visual" w={"80%"} />
          </Box>

          <Box maxW="600px" align="start">
            <Text
              fontSize="14px"
              fontWeight="400"
              color="#092C4C"
              mb={4}
              align={"start"}
            >
              “I've had the pleasure of working with Transfi.com for the past
              year, and I must say, their commitment and support system have
              been nothing short of exemplary. The team's dedication to both
              technical and operational excellence is evident in how seamlessly
              and efficiently they handle every task.”
            </Text>
            <Text fontSize="24px" fontWeight="700" color="#092C4C">
              Suleman Kazim
            </Text>
            <Text fontSize="16px" fontWeight="400" color="#525C76">
              CEO - Parallax
            </Text>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Step4BusinessDetails;
