import { Route, Routes, Navigate } from "react-router-dom";

import NewPassword from "../modules/login/NewPassword";
import LoginFlow from "../modules/login/LoginFlow";
import FillDetails from "../modules/signup/FillDetails";
import InviteSignup from "../modules/signup/InviteSignup";
import RequestJoin from "../modules/signup/RequestJoin";
import CustomerSignUpFlow from "../modules/signup/CustomerSignUpFlow";
import Signup from "../modules/signup2/Signup";

export default function UnauthorizedRoutes(props) {
    return (
        <>
            <div>
                <Routes>
                    <Route path="*" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<LoginFlow />}></Route>
                    <Route path="/signup" element={<Signup />}></Route>
                    {/* <Route path="/signup2" element={<Signup2 />}></Route> */}
                    <Route path="/fill-details" element={<FillDetails />}></Route>
                    <Route path="account/login-pwd/new" element={<NewPassword />}></Route>
                    <Route path="account/login-pwd/reset" element={<NewPassword reset={true} />}></Route>
                    <Route path="/signup-invite" element={<InviteSignup />}></Route>
                    <Route path="/request-join" element={<RequestJoin />}></Route>
                    <Route path="/signup-customer" element={<CustomerSignUpFlow />}></Route>
                </Routes>
            </div>
        </>
    );
}
