import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  Input,
  VStack,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import HelmetWrapper from "../../utils/HelmetWrapper";
import TransfiLogo from "../../assets/transfiLogo.svg";
import StepsAsset from "../../assets/StepsAsset.png";

const Step1AccountDetails = ({ formData, handleChange, handleNext, handleBack }) => {
  

  const countries = [
    "United States",
    "United Kingdom",
    "India",
    "Canada",
    "Germany",
    "Australia",
    "Singapore",
    "France",
    "Japan",
    "Other",
  ];
  return (
    <>
      <HelmetWrapper
        title="Transfi Pay Sign Up"
        description="Transfi Pay Signup Page"
      />
      <Flex w="full" h="100vh" overflow="hidden">
        <Flex flex={1} direction="column" p={14} justify="start">
          <Box>
            <Image src={TransfiLogo} alt="logo" w="150px" />
          </Box>

          <Box textAlign="start" marginTop={"14"}>
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="ghost"
              onClick={handleBack}
              color={"#1A4FD6"}
              fontWeight={"600"}
              p={1}
              fontSize="sm"
            >
              Back
            </Button>
            <Text fontSize="3xl" lineHeight={"40px"}  fontWeight="normal" mb={8}>
            Let’s get you started with a <br/>new TransFi Account
            </Text>

            <VStack spacing={5} align="stretch">
              <FormControl isRequired>
                <FormLabel fontWeight="light" fontSize="sm">
                  Business Email
                </FormLabel>
                <Input
                  backgroundColor={"#F7FAFC"}
                  type="email"
                  placeholder="Enter Business Email"
                  value={formData.email || ""}
                  onChange={(e) => handleChange("email", e.target.value)}
                  
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontWeight="light" fontSize="sm">
                  Business Name
                </FormLabel>
                <Input
                  backgroundColor={"#F7FAFC"}
                  type="text"
                  placeholder="Enter Business Name"
                  value={formData.businessName || ""}
                  onChange={(e) => handleChange("businessName", e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontWeight="light" fontSize="sm">
                  Country of Registration
                </FormLabel>
                <Select
                  backgroundColor={"#F7FAFC"}
                  placeholder="Select Country"
                  value={formData.country || ""}
                  onChange={(e) => handleChange("country", e.target.value)}
                >
                  {countries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel fontWeight="light" fontSize="sm">
                  Business Website
                </FormLabel>
                <Input
                  backgroundColor={"#F7FAFC"}
                  type="url"
                  placeholder="https://business.com"
                  value={formData.businessWebsite || ""}
                  onChange={(e) =>
                    handleChange("businessWebsite", e.target.value)
                  }
                />
              </FormControl>

              <Button
                onClick={handleNext}
                bg="#1A4FD6"
                color="white"
                w="full"
                isDisabled={
                  !formData.businessName ||
                  !formData.email ||
                  !formData.country ||
                  !formData.businessWebsite
                }
                _hover={{ bg: "#163A9E" }}
                _active={{ bg: "#122E7F", transform: "scale(0.98)" }}
              >
                Next
              </Button>
            </VStack>
          </Box>
        </Flex>

        {/* Right Side */}
        <Flex
          flex={2}
          className="gradient-background"
          flexDirection="column"
          justify="center"
          align="center"
          textAlign="center"
          p={5}
        >
          <Box mb={6} align="center" justify="center">
            <Image src={StepsAsset} alt="Visual" w={"80%"} />
          </Box>

          <Box maxW="600px" align="start">
            <Text
              fontSize="14px"
              fontWeight="400"
              color="#092C4C"
              mb={4}
              align={"start"}
            >
              “I've had the pleasure of working with Transfi.com for the past
              year, and I must say, their commitment and support system have
              been nothing short of exemplary. The team's dedication to both
              technical and operational excellence is evident in how seamlessly
              and efficiently they handle every task.”
            </Text>
            <Text fontSize="24px" fontWeight="700" color="#092C4C">
              Suleman Kazim
            </Text>
            <Text fontSize="16px" fontWeight="400" color="#525C76">
              CEO - Parallax
            </Text>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Step1AccountDetails;
